import find from "lodash/find";
import findIndex from "lodash/findIndex";
import qs from "query-string";
import * as React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { ViewsByTypes } from "src/api";
import Banner from "src/components/Banner";
import NavigationDots from "src/components/NavigationDots";
import Page from "src/components/Page";
import Tabs from "src/components/Tabs";
import constants from "src/helpers/constants";
import history from "src/helpers/history";
import urls from "src/helpers/urls";
import useDeepCompareEffect from "src/helpers/useDeepCompareEffect";
import utility from "src/helpers/utility";
import * as AppActions from "src/store/actions/app";
import * as ExhibitActions from "src/store/actions/exhibit";
import { IReduxState } from "src/store/reducers";
import {
  ExhibitDataType,
  LocationType,
  VisitedExhibitType,
} from "src/store/types";
import Content from "./Content";
import RelatedArticles from "./RelatedArticles";

import FAB from "src/components/FAB";
import { setVisitedExhibit } from "src/store/actions";
import {
  BottomActionsContainer,
  SwipePageContentWrapper,
  TabContainer,
  TabPageListWrapper,
} from "./styled";
import InAppExtras from "src/components/InAppExtras"; // Importing InAppExtras component and related types

import { AddBottomSpacing } from "src/components/InAppExtras/styled";

type Props = IScreenProps & {
  activeLanguage: string;
  exhibitId?: string;
  getExhibit?: Function;
  removeTemporaryTopicsFromSecretExhibits?: Function;
  locationId?: string;
  locations?: {
    [locationId: string]: {
      data: LocationType;
    };
  };
  exhibitData: ExhibitDataType;
  setCurrentExhibitId?: Function;
  currentExhibitViewsBy: string;
  visitedExhibits: { [exhibitId: string]: VisitedExhibitType };
};

const Exhibit: React.FC<Props> = props => {
  const {
    activeLanguage,
    exhibitId,
    getExhibit,
    locationId,
    locations,
    location,
    exhibitData,
    currentExhibitViewsBy,
    visitedExhibits,
  } = props;

  const sliderRef = React.useRef(null);
  const allExhibitsRef = React.useRef([]);
  const isSliderMoveRef = React.useRef(false);
  // const [locationId, setCurrentLocationId] = React.useState(locationId || "");
  let areas = locations?.[locationId]?.data?.areas || [];
  // Filter out status 3 areas unless the current exhibit is part of it or the area is in visitedAreas
  areas = areas.filter(area => {
    if (area.status !== 3) {
      return true;
    }
    const isCurrentExhibitInArea = area.exhibits.some(
      exhibit => exhibit?._id?.$oid === exhibitId
    );
    //boil this down to a list of area ids that have been visited
    const visitedAreas = Object.values(visitedExhibits)
      .filter(visitedExhibit => visitedExhibit.locationId === locationId)
      .map(visitedExhibit => visitedExhibit.areaId);

    // if the current exhibit is in the area or the area has been visited, show it
    return isCurrentExhibitInArea || visitedAreas.includes(area._id.$oid);
  });

  const defaultLanguage = locations?.[locationId]?.data?.defaultLanguage || "";

  let allExhibits = [] as Array<{
    id: string;
    areaId: string;
    areaIndex: number;
  }>;
  for (let index = 0; index < areas.length; index++) {
    const area = areas[index];
    const exhibits = area?.exhibits || [];
    const tabs = exhibits.map(exhibit => ({
      id: exhibit?._id?.$oid,
      areaId: area?._id?.$oid,
      areaIndex: index,
    }));
    allExhibits = [...allExhibits, ...tabs];
  }
  allExhibitsRef.current = allExhibits;

  let exhibitSlideIndex = findIndex(allExhibits, { id: exhibitId });
  exhibitSlideIndex = exhibitSlideIndex <= -1 ? 0 : exhibitSlideIndex;
  const exhibitSlide = find(allExhibits, { id: exhibitId });
  const areaIndex = exhibitSlide?.areaIndex || 0;
  const [currentAreaIndex, setCurrentAreaTabIndex] = React.useState(areaIndex);
  const [currentExhibitIndex, setCurrentExhibitIndex] = React.useState(
    exhibitSlideIndex
  );

  // set current location id
  // React.useEffect(() => {
  //   if (locationId) {
  //     alert("set current location id - add logic back to application");
  //     // setCurrentLocationId(locationId);
  //   }
  // }, [locationId]);

  // load exhibit
  useDeepCompareEffect(() => {
    const parsedQuery = qs.parse(location.search);
    props.setCurrentExhibitId(
      exhibitId,
      parsedQuery.feature || currentExhibitViewsBy // if we have feature set in url then use it , or else use it from redux. In case user is swiping mostly feature will be set in redux and we will use it here
    );
    const feat = parsedQuery.feature;
    const reqLocationId = feat !== "scan" ? locationId : undefined;
    if (exhibitId) {
      getExhibit(exhibitId, false, reqLocationId);
    }
  }, [exhibitId]);

  // update branch banner data
  React.useEffect(() => {
    const linkData = {
      data: {
        exhibit_id: exhibitId,
      },
    };

    // close journey and open it again with new data, so that mobile will get proper data
    // if branch banner data is not set then
    if (!window.isAppBannerDataSet) {
      setTimeout(() => {
        window.branch.closeJourney();
        setTimeout(() => {
          window.branch.setBranchViewData(linkData);
          window.branch.track("pageview");
          // this is used to check in other page whether to update branch banner data or not
          window.isAppBannerDataSet = true;
        }, 1000);
      }, 1000);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    // if exhibit slide index or area index changes then update states
    if (currentAreaIndex !== areaIndex) {
      setCurrentAreaTabIndex(areaIndex);
    }
    if (currentExhibitIndex !== exhibitSlideIndex) {
      setCurrentExhibitIndex(exhibitSlideIndex);
    }
  }, [exhibitSlideIndex, areaIndex]); // eslint-disable-line

  const updateAutoHeight = () => {
    if (sliderRef && sliderRef.current) {
      // update autoheight of slide
      sliderRef.current.updateAutoHeight();
    }
  };

  const onChangeExhibit = (navigateExhibitId, exhibitIndex, newAreaIndex) => {
    setCurrentExhibitIndex(exhibitIndex);
    setCurrentAreaTabIndex(newAreaIndex);
    // remove temporary exhibits on swipe
    props.removeTemporaryTopicsFromSecretExhibits();
    // here we are pushing url so user can have back button functionality in browser
    history.push(urls.exhibit(navigateExhibitId, ViewsByTypes.menu));
  };

  const params = {
    activeSlideKey: "" + currentExhibitIndex,
    shouldSwiperUpdate: true,
    autoHeight: true,
    noSwiping: window.screen.width > 959,
    on: {
      sliderMove: () => {
        // track that user is moving slider by hand
        isSliderMoveRef.current = true;
      },
      slideChange: () => {
        if (sliderRef.current && isSliderMoveRef.current) {
          // if user moved slider then only run this logic
          // if slide changes automatically by changing url then dont run this logic
          isSliderMoveRef.current = false;
          const activeIndex = sliderRef.current.activeIndex;
          const tabObj = allExhibitsRef.current[activeIndex];
          if (tabObj) {
            onChangeExhibit(tabObj.id, activeIndex, tabObj.areaIndex);
          }
        }
      },
    },
  };

  const exhibitImage = exhibitData?.exhibitImageThumb;
  let orgName = locations?.[locationId]?.data?.companyName;

  const pageTitle = utility.getName(
    exhibitData.name,
    activeLanguage,
    defaultLanguage
  );
  const currentAreaExibits = areas?.[areaIndex]?.exhibits || [];
  const exhibitIndexInArea = findIndex(currentAreaExibits, {
    _id: { $oid: exhibitId },
  });

  const getTabItems = () => {
    const tabItems = areas.map((area, index) => {
      const areaName = utility.getName(
        area.name,
        activeLanguage,
        defaultLanguage
      );
      const areaId = area?._id?.$oid;
      return {
        label: areaName,
        key: areaId,
        pagesCount: area?.exhibitOrder?.length,
        index,
        exhibitsLength: area.exhibits.length,
      };
    });
    return tabItems;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    // Because of a bug in Safari, we need to set the scroll behavior to "unset" before setting it to "auto"
    // https://forums.developer.apple.com/forums/thread/703294
    // let make sure to set it back to smooth after setting it to unset
    document.documentElement.style.scrollBehavior = "unset";
    document.documentElement.style.scrollBehavior = "auto";
  }, [currentExhibitIndex, exhibitId]);

  // State variables for managing in-app extras
  const [hasCoordinates, setHasCoordinates] = React.useState(
    exhibitData?.metadata?.lat && exhibitData?.metadata?.lng
  );
  const [showExtras, setShowExtras] = React.useState(hasCoordinates);

  // Update the showFAB condition to only show when enableSharing is true AND extras are not showing
  const showFAB = exhibitData.enableSharing && !showExtras;

  // Initialize showExtras based on coordinates presence
  React.useEffect(() => {
    setShowExtras(exhibitData?.metadata?.lat && exhibitData?.metadata?.lng);
    setHasCoordinates(exhibitData?.metadata?.lat && exhibitData?.metadata?.lng);
  }, [exhibitData]);

  return (
    <Page>
      <Banner
        locationId={locationId}
        image={exhibitImage}
        useImageFromLocation={false}
      />
      <span>
        {areas.length > 0 && (
          <TabContainer>
            <Tabs
              activeLanguage={activeLanguage}
              exhibitId={allExhibits?.[currentExhibitIndex || 0]?.id}
              locationId={locationId}
              index={currentAreaIndex}
              items={getTabItems()}
              onTabClick={tab => {
                const area = areas?.[tab.index];
                const firstExhibitid = area?.exhibits?.[0]?._id?.$oid;
                const exhibitIndex = findIndex(allExhibits, {
                  id: firstExhibitid,
                });
                onChangeExhibit(firstExhibitid, exhibitIndex, tab.index);
                props.removeTemporaryTopicsFromSecretExhibits();
              }}
            />
          </TabContainer>
        )}

        <NavigationDots
          total={currentAreaExibits.length}
          current={exhibitIndexInArea}
        />
        <SwipePageContentWrapper
          onLoad={() => {
            if (sliderRef && sliderRef.current) {
              sliderRef.current.updateAutoHeight();
            }
          }}
        >
          <Swiper
            {...params}
            getSwiper={ref => {
              sliderRef.current = ref;
            }}
          >
            {allExhibits.map((exhibit, index) => {
              return (
                <div key={index} id={"" + index}>
                  <Content
                    key={exhibit.id}
                    activeLanguage={activeLanguage}
                    defaultLanguage={defaultLanguage}
                    exhibitId={exhibit.id}
                    isVisible={index === currentExhibitIndex}
                    updateAutoHeight={updateAutoHeight}
                    locationId={locationId}
                  />
                </div>
              );
            })}
          </Swiper>

          <TabPageListWrapper>
            <RelatedArticles
              activeLanguage={activeLanguage}
              exhibitId={allExhibits?.[currentExhibitIndex || 0]?.id}
              locationId={locationId}
            />
          </TabPageListWrapper>
        </SwipePageContentWrapper>

        {(hasCoordinates || showFAB) && (
          <BottomActionsContainer>
            {hasCoordinates && (
              <InAppExtras
                title="Nearby Attractions"
                showingExtras={setShowExtras}
                lat={exhibitData.metadata.lat}
                lng={exhibitData.metadata.lng}
              />
            )}

            {showFAB && (
              <FAB
                organizationName={orgName}
                pageName={pageTitle}
                qrImage={
                  process.env.REACT_APP_API_URL +
                  "exhibit/qr?id=" +
                  allExhibits?.[currentExhibitIndex || 0]?.id
                }
                hasCoordinates={hasCoordinates}
              />
            )}
          </BottomActionsContainer>
        )}

        {/* This space is added when the cards are being shown  to ensure proper layout */}
        {showExtras && <AddBottomSpacing />}
      </span>
    </Page>
  );
};

export default connect(
  (state: IReduxState, ownProps: Props) => {
    const idFromUrl = ownProps.match?.params?.id;
    const id = state.app?.currentExhibitId;
    const exhibitDetails = state.topics?.exhibitDetails?.[id];
    const exhibit = exhibitDetails?.data || ({} as ExhibitDataType);
    const locationId = state.app?.currentMenuId || "";
    const locations = state.location?.locations;
    const activeLanguage = state.language?.activeLanguage;

    let exhibitId = "";

    if (idFromUrl) {
      // if we have topic id in url , then take it
      exhibitId = idFromUrl;
    } else if (id) {
      // if we have topic id set in redux , then take it
      exhibitId = id;
    } else {
      // else take default id
      exhibitId = constants.DEFAULT_TOPIC_ID();
    }

    // filter secret Areas
    let areaId = state.topics?.exhibitDetails?.[exhibitId]?.data?.areaId;
    let area = state.areas?.areas?.[areaId]?.data;
    let areaStatus = area?.status;
    // if areaStatus = 3 then add to vistedExhibits
    if (areaStatus === 3) {
      setVisitedExhibit(locationId, "", areaId, exhibitId);
    }

    let visitedExhibits = state.explored.visitedExhibits || {};

    return {
      activeLanguage,
      exhibitId,
      exhibitData: exhibit,
      locationId,
      locations,
      visitedExhibits,
      currentExhibitViewsBy: state.app?.currentExhibitViewsBy,
    };
  },
  {
    removeTemporaryTopicsFromSecretExhibits:
      ExhibitActions.removeTemporaryTopicsFromSecretExhibits,
    getExhibit: ExhibitActions.getExhibit,
    setCurrentExhibitId: AppActions.setCurrentExhibitId,
  }
)(Exhibit);
