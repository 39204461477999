import React, { useEffect, useState } from "react";
import { ImQrcode } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { createPortal } from "react-dom";
import QRCodeScreen from "../QRCodeScreen";
import { ShareNotification } from "../ShareButton/styled";
import {
  FABContainer,
  FABWrapper,
  RotateOnHover,
  ModalBackdrop,
  ModalContent,
  CloseButton,
} from "./styled";

//FloatingActionButton
const FAB = ({ organizationName, pageName, qrImage, hasCoordinates }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState("");
  const [shareBtn, setShareBtn] = useState(false);
  const shareNow = () => {
    try {
      const shareData = {
        title: "Share the Joy: " + organizationName,
        text: pageName,
        url: window.location.href,
      };
      if (navigator.share) {
        if (navigator.canShare) {
          navigator
            .share(shareData)
            .then(() => {
              setNotification("Content shared successfully!");
            })
            .catch(error => {
              setNotification(
                "An error occurred while sharing. Please try again."
              );
            });
        } else {
          setNotification("This content cannot be shared on this device.");
        }
      } else {
        setNotification("Sharing is not supported on this device.");
      }
    } catch (error) {
      setNotification("This device cannot share!");
    }
    setShareBtn(!shareBtn);
  };
  const renderNotification = () => {
    if (notification) {
      return <ShareNotification>{notification}</ShareNotification>;
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => setShareBtn(false), 5000);
  }, [shareBtn]);

  return (
    <FABContainer>
      <FABWrapper hasCoordinates={hasCoordinates}>
        <RotateOnHover>
          <ImQrcode onClick={toggleModal} style={{ width: 30, height: 30 }} />
        </RotateOnHover>
      </FABWrapper>
      {isOpen &&
        createPortal(
          <div>
            <ModalBackdrop>
              <ModalContent>
                <CloseButton onClick={toggleModal}>
                  <RotateOnHover>
                    <IoMdClose />
                  </RotateOnHover>
                </CloseButton>
                <QRCodeScreen
                  organizationName={organizationName}
                  pageName={pageName}
                  image={qrImage}
                  onShareButton={() => shareNow()}
                />
              </ModalContent>
            </ModalBackdrop>
            {shareBtn && renderNotification()}
          </div>,
          document.body
        )}
    </FABContainer>
  );
};

export default FAB;
